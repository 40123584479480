<template>
  <popup-modal ref="popup">
    <h2 class="titulo" style="margin-top: 0">{{ title }}</h2>
    <div class="nomeusuario">{{ message }}</div>
    <div class="btns">
      <button class="ok-btn mt-6 mr-3" @click="_confirm">{{ okButton }}</button>
      <button class="cancel-btn mt-6" @click="_cancel">
        {{ cancelButton }}
      </button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./PopupModal.vue";

export default {
  name: "ConfirmDialogue",

  components: { PopupModal },

  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: "Cancelar", // text for cancel button

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
};
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.titulo {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.nomeusuario {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #545454;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
}

.ok-btn {
  padding: 0.5em 1em;
  /* background-color: #e0acb2ff; */
  background-color: #198754;
  /* color: #e0465aff; */
  color: #ffffffff;
  /* border: 2px solid #e66374ff; */
  border-radius: 5px;
  /* font-weight: bold; */
  font-size: 16px;
  /* text-transform: uppercase; */
  cursor: pointer;
}

.cancel-btn {
  padding: 0.5em 1em;
  /* background-color: #d5eae7; */
  background-color: #dc3545;
  /* color: #35907f; */
  color: #ffffffff;
  /* border: 2px solid #0ec5a4; */
  border-radius: 5px;
  /* font-weight: bold; */
  font-size: 16px;
  /* text-transform: uppercase; */
  cursor: pointer;
}
</style>
