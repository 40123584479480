<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-data-table
          dense
          :headers="headers"
          :items="compModulosLista"
          sort-by="nome"
          class="elevation-10"
          :search="search"
          :loading="!compModulosProgress.length"
          loading-text="Carregando..."
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-view-module-outline</v-icon>
              <v-toolbar-title>Módulos do Sistema</v-toolbar-title>
              <v-icon class="ml-2" small>mdi-asterisk</v-icon>
              <v-spacer></v-spacer>
              <!-- <v-dialog v-model="dialog" max-width="500px"> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSyncModComp()"
                  >
                    mdi-sync
                  </v-icon>
                </template>
                <span>Sincroniza</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSelecionarModuloEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Módulo</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
              <!-- </v-dialog> -->
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="6" class="ml-2" align-self="end">
                  <v-chip-group mandatory active-class="primary">
                    <v-chip @click="listaModulosAtivos = 'ativos'">
                      Ativos
                    </v-chip>
                    <v-chip @click="listaModulosAtivos = 'inativos'">
                      Inativos
                    </v-chip>
                    <v-chip @click="listaModulosAtivos = 'todos'">
                      Todos
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarModuloEdicao(item)"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="indigo darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarModulo(item)"
                >
                  mdi-toy-brick-outline
                </v-icon>
              </template>
              <span>Componentes</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  :color="metAtivoColor(item.ativo)"
                  v-bind="attrs"
                  v-on="on"
                  @click="metParaAtivacao(item)"
                >
                  {{ item.ativo ? "mdi-check-bold" : "mdi-close-thick" }}
                </v-icon>
              </template>
              <span>{{ item.ativo ? "Ativo" : "Inativo" }}</span>
            </v-tooltip>

            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";

const { mapGetters, mapState, mapActions } =
  createNamespacedHelpers("adminModulos");

export default {
  components: { ConfirmDialogue },
  // mixins: [],
  name: "AdminModulosLista",
  data: () => ({
    listaModulosAtivos: "ativos",
    search: "",
    dialog: false,
    headers: [
      { text: "Código", value: "id" },

      { text: "Modulo", value: "modulo", align: "start" },
      { text: "Ações", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState({
      staErro: "erro",
      staModulos: "modulos",
      storModuloSelecionado: "moduloSelecionado",
    }),
    ...mapGetters({
      getModulosAtivos: "modulosAtivos",
      getModulosInativos: "modulosInativos",
    }),

    compModulosLista() {
      return this.listaModulosAtivos == "ativos"
        ? this.getModulosAtivos
        : this.listaModulosAtivos == "inativos"
        ? this.getModulosInativos
        : this.staModulos;
    },
    compModulosProgress() {
      return this.staModulos;
    },
  },

  created() {
    register(this.$store);
    this.actListarModulos();
  },

  watch: {
    staErro() {
      if (this.staErro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.staErro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {},

  methods: {
    ...mapActions([
      "actSelecionarModulo",
      "actListarModulos",
      "actAtivarModulo",
      "actSyncModComp",
    ]),

    metAtivoColor(ativo) {
      if (ativo) return "green darken-1";
      else return "red darken-1";
    },

    metSelecionarModuloEdicao(modulo) {
      this.actSelecionarModulo({ modulo });
      this.$router.push({ name: "AdminModulosForm" });
    },

    metSelecionarModulo(modulo) {
      this.actSelecionarModulo({ modulo });
      this.$router.push({ name: "AdminModulosCompForm" });
    },

    metParaAtivacao(modulo) {
      this.actAtivarModulo({ modulo });
    },

    metVoltar() {
      this.$router.back();
    },

    metRecarregar() {
      this.actListarModulos();
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },
    async metSyncModComp() {
      await this.$root.snackbar.show({
        type: "warning",
        message: "Sincronização Componentes Módulos Iniciada!",
      });
      await this.actSyncModComp({
        modulo: "todos",
      });
      await this.$root.snackbar.show({
        type: "success",
        message: "Sincronização Componentes Módulos Finalizada!",
      });
    },
  },
};
</script>
